import React, { useState } from 'react'
import { useIsClient } from '~/hooks/use-is-client'
import { useForm } from '@mantine/form'
import { TextInput, Textarea, Button, Group } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { TbCheck as OkIcon, TbX as NgIcon } from 'react-icons/tb'

interface Props {}

interface FormValuesProps {
  name: string
  org: string
  email: string
  body: string
}

const FORM_NAME = 'contact'

const validateRequired = (value: string) => {
  if (value.length === 0) {
    return `入力してください`
  } else {
    return null
  }
}

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
    .join(`&`)
}

const ContactForm: React.FC<Props> = () => {
  /* NOTE: avoid hydration problem
   *  https://github.com/gatsbyjs/gatsby/discussions/17914
   *  https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
   */
  const { isClient } = useIsClient()
  const [loading, setLoading] = useState<boolean>(false)

  const form = useForm({
    initialValues: {
      'form-name': FORM_NAME,
      name: '',
      org: '',
      email: '',
      body: '',
    },
    validate: {
      name: (value) => validateRequired(value),
      body: (value) => validateRequired(value),
      email: (value) =>
        /^\S+@\S+$/.test(value)
          ? null
          : `メールアドレスを正しく入力してください`,
    },
  })

  const handleSubmit = (values: FormValuesProps) => {
    setLoading(true)

    fetch('/', {
      method: `POST`,
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
      body: encode({
        ...values,
      }),
    })
      .then(({ status }) => {
        if (status !== 200) {
          throw Error(`status: ${status}`)
        }
        showNotification({
          title: 'Thank you!',
          message: `お問い合わせありがとうございました👏`,
          icon: <OkIcon />,
          color: 'teal',
        })

        form.reset()
      })
      .catch(() => {
        showNotification({
          title: 'Apologies...',
          message: `エラーにより送信することができませんでした。申し訳ございません🙏`,
          icon: <NgIcon />,
          color: 'red',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!isClient) return null
  return (
    <form
      name={FORM_NAME}
      onSubmit={form.onSubmit(handleSubmit)}
      method="POST"
      data-netlify="true"
    >
      <TextInput
        withAsterisk
        name="name"
        label="お名前"
        {...form.getInputProps('name')}
      />
      <TextInput name="org" label="企業名" {...form.getInputProps('org')} />
      <TextInput
        withAsterisk
        name="email"
        label="メールアドレス"
        placeholder="your@email.com"
        {...form.getInputProps('email')}
      />
      <Textarea
        withAsterisk
        name="body"
        label="お問い合わせ内容"
        autosize
        minRows={4}
        {...form.getInputProps('body')}
      />

      <input type="hidden" name="form-name" value={FORM_NAME} />

      <Group position="center" mt="md">
        <Button type="submit" loading={loading}>
          送 信
        </Button>
      </Group>
    </form>
  )
}

export default ContactForm
