import React from 'react'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import Form from '~/components/contact-form'
import { css } from '@emotion/react'
import { COLOR_GRAY_DARK } from '~/styles/colors'
import { PAGE_HEADING_STYLE } from '~/styles/common'

interface Props {}

const ContactPage: React.FC<Props> = () => {
  return (
    <Layout>
      <h2 css={style.heading}>お問い合わせ</h2>
      <div css={style.wrapper}>
        <Form />
      </div>
    </Layout>
  )
}

const style = {
  heading: css`
    ${PAGE_HEADING_STYLE}
  `,
  wrapper: css`
    max-width: 540px;
    margin: 0 auto;

    form {
      > div {
        margin-bottom: 1em;
      }

      label {
        color: ${COLOR_GRAY_DARK};
      }

      button {
        margin-top: 2em;
      }
    }
  `,
}

export default ContactPage

const SEO_TITLE = `お問い合わせ`
const SEO_DESC = `ストイック倶楽部は紹介制の倶楽部ではありませんが、まずはお気軽にお問い合わせくださいね。ステイしてほしいホテルなどのリクエストも受け付けております。`

export const Head: React.FC<HeadProps> = () => {
  return <SEO title={SEO_TITLE} description={SEO_DESC} />
}
